import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export function Paragraph({
  children,
  className,
  ...props
}: { children: ReactNode, className?: string } & Record<string, any>) {
  return (
    <p className={twMerge(["m-0 py-4", className])} {...props}>
      {children}
    </p>
  );
}
