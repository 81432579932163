import { ErrorInfo, ReactNode } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

import { ConfirmButton } from '@components/Buttons/Buttons';
import { Paragraph } from '@components/Typography';
import { log, logBreadcrumb } from '@lib/utils';

/**
 * Our standard Error Boundary Fallback component
 * @param {error, resetErrorBoundary}
 * @returns JSX element
 */
function ErrorBoundaryFallback({
  error,
  resetErrorBoundary,
  location,
}: FallbackProps & { location: string }) {
  return (
    <div role="alert">
      <div className="max-w-maxWidth mx-auto my-0.5 border border-solid border-grey-light p-2">
        <Paragraph>
          Uh oh, we had a little problem displaying this section.
        </Paragraph>
        <Paragraph>
          Our Web Team has been notified and will be looking into it shortly.
        </Paragraph>
        <Paragraph>Error code:</Paragraph>
        <pre className="dark:bg-grey-dark rounded bg-grey-light p-2.5">
          {error.message}
          <br />
          {error.name}
          <br />
          Thrown by: {location}
        </pre>
        <Paragraph>
          If this error persists, please get in contact with our Customer
          Service Team for help.
        </Paragraph>
        <ConfirmButton onClick={resetErrorBoundary}>Try again</ConfirmButton>
      </div>
    </div>
  );
}

function handleBoundaryError(error: Error, info: ErrorInfo, location: string) {
  // Sentry
  logBreadcrumb({
    message: 'Component stack for error',
    category: 'error',
    level: 'error',
    data: { componentStack: info.componentStack, location: location },
  });
  log(error, location);
}

export function MyErrorBoundary({
  children,
  location,
}: {
  children: ReactNode;
  location: string;
}) {
  return (
    <ErrorBoundary
      fallbackRender={(props) => (
        <ErrorBoundaryFallback {...props} location={location} />
      )}
      onError={(error, info) => handleBoundaryError(error, info, location)}
    >
      {children}
    </ErrorBoundary>
  );
}
