import {
  addBreadcrumb,
  Breadcrumb,
  captureException,
  captureMessage,
} from '@sentry/nextjs';

export const getErrorMessage = (error: unknown) => {
  if (error instanceof Error) return error.message;
  return String(error);
};

/**
 * Send to sentry and our logs if on server
 * @param breadcrumb Sentry Breadcrumb
 */
export const logBreadcrumb = (breadcrumb: Breadcrumb) => {
  addBreadcrumb(breadcrumb); // send to sentry
  if (typeof window === 'undefined') {
    console.log(breadcrumb.message); // send to our logs
    console.log(JSON.stringify(breadcrumb.data)); // send to our logs
  }
};

/**
 * Used to send errors and messages to our internal logs and sentry
 * @param message either a string or an error
 */
export const log = (message: unknown | string | Error, location?: string) => {
  const isServer = typeof window === 'undefined';

  if (message instanceof Error) {
    captureException(message); // send to sentry
    if (isServer)
      console.error(
        `Encountered error, triggered by: ${location}. error: ${message.message}`
      ); // send to our logs
  } else {
    // captureMessage(String(message)); // send to sentry
    if (isServer) console.log(message); // send to our logs
  }
};
