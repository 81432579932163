import Link from 'next/link';
import React, { ReactElement, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export function TextLink({
  children,
  href,
  className,
  ...props
}: {
  children: ReactNode;
  href: string;
  className?: string;
} & Record<string, any>): ReactElement {
  return (
    <Link
      prefetch={process.env.NEXT_PUBLIC_PREFETCH === 'true'}
      href={href}
      {...props}
      className={twMerge(['clickable', className])}
    >
      {children}
    </Link>
  );
}
