import { FC, ReactNode } from 'react';

import { MyErrorBoundary } from '@components/Alerts/ErrorBoundaryFallback';
import { Footer } from '@components/Footer/Footer';
import { Header } from '@components/Header/Header';

type Props = {
  children: ReactNode;
  includeHeader?: boolean;
  includeFooter?: boolean;
};

export const ArticleLayout: FC<Props> = ({
  children,
  includeHeader = true,
  includeFooter = true,
}) => (
  <>
    {includeHeader && <Header />}
    <MyErrorBoundary location="ArticleLayout">
      <main className="bg-cream text-blue container pt-[50px]">{children}</main>
    </MyErrorBoundary>
    {includeFooter && <Footer />}
  </>
);
