import { Cross1Icon, HamburgerMenuIcon } from '@radix-ui/react-icons';
import * as Portal from '@radix-ui/react-portal';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const NavLinks = [
  { href: '/', title: 'Home' },
  { href: '/services', title: 'Services' },
  { href: '/about', title: 'About' },
  { href: '/articles', title: 'Articles' },
  { href: '/contact', title: 'Contact' },
];

function NavMenu() {
  return (
    <menu className="m-0 flex flex-col gap-4 px-0 py-[50px] text-center xl:flex-row">
      {NavLinks.map((link) => (
        <Link
          href={link.href}
          key={link.href}
          className="select-none rounded px-3 py-2 font-secondary text-base font-bold leading-4 text-blue-light no-underline outline-none before:mr-1 before:content-['/'] hover:underline xl:text-tan"
        >
          {link.title}
        </Link>
      ))}
    </menu>
  );
}

export function Menu() {
  const [showMenu, setShowMenu] = useState(false);

  const router = useRouter();

  const toggleMenu = () => {
    setShowMenu((state) => !state);
  };

  useEffect(() => {
    const handleRouteChange = (url: any, shallow: any) => {
      setShowMenu(false);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  const ICON_SIZE = 40;

  return (
    <>
      <nav className="hidden xl:block">
        {/* Desktop */}
        <NavMenu />
      </nav>

      <div className="flex min-h-[50px] items-center xl:hidden">
        {/* Mobile */}
        <button
          onClick={toggleMenu}
          className="border-none bg-transparent p-2.5 text-tan"
        >
          <HamburgerMenuIcon
            width={ICON_SIZE}
            height={ICON_SIZE}
            color="#ccd4d8"
          />
        </button>
        {showMenu ? (
          <Portal.Root>
            <div className="fixed left-0 top-0 z-10 h-screen w-full bg-blue">
              <button
                onClick={toggleMenu}
                className="fixed right-8 top-8 border-none bg-transparent text-tan"
              >
                <Cross1Icon
                  width={ICON_SIZE}
                  height={ICON_SIZE}
                  color="#ccd4d8"
                />
              </button>
              <nav>
                <NavMenu />
              </nav>
            </div>
          </Portal.Root>
        ) : null}
      </div>
    </>
  );
}
