import Link from 'next/link';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export function LinkButton({
  href,
  children,
  className,
}: {
  href: string;
  children: ReactNode;
  className?: string;
}) {
  return (
    <Link
      className={twMerge([
        'bg-tan px-4 py-2 text-xl font-bold text-blue underline decoration-blue decoration-1 underline-offset-[0.1em] duration-500 hover:decoration-3 hover:duration-200',
        className,
      ])}
      href={href}
    >
      {children}
    </Link>
  );
}

export function LinkButtonRound({
  href,
  children,
  className,
}: {
  href: string;
  children: ReactNode;
  className?: string;
}) {
  return (
    <LinkButton
      href={href}
      className={twMerge([
        'text-center flex h-[94px] w-[94px] place-items-center self-end rounded-full bg-tan font-secondary text-xs font-bold md:h-[144px] md:w-[144px] md:text-xl',
        className,
      ])}
    >
      {children}
    </LinkButton>
  );
}

export function ExitPreview() {
  return (
    <LinkButton className="fixed bottom-5 left-5" href="/api/exit-preview">
      Exit Preview
    </LinkButton>
  );
}
