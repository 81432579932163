import { FC, ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { MyErrorBoundary } from '@components/Alerts/ErrorBoundaryFallback';
import { Footer } from '@components/Footer/Footer';
import { Header } from '@components/Header/Header';

type Props = {
  children: ReactNode;
  includeHeader?: boolean;
  includeFooter?: boolean;
};

export const StandardLayout: FC<Props> = ({
  children,
  includeHeader = true,
  includeFooter = true,
}) => (
  <>
    {includeHeader && <Header />}
    <MyErrorBoundary location="StandardLayout">{children}</MyErrorBoundary>
    {includeFooter && <Footer />}
  </>
);
