/* eslint-disable react/jsx-one-expression-per-line */
import {
  PortableText,
  PortableTextMarkComponentProps,
} from '@portabletext/react';
import React, { ReactNode } from 'react';

import { Paragraph } from './Paragraph';
import { TextLink } from './TextLink';

import type { PortableTextBlock } from '@portabletext/types';

const SubscriptRenderer = (props: PortableTextMarkComponentProps) => (
  <sub>{props.children}</sub>
);

const SuperscriptRenderer = (props: PortableTextMarkComponentProps) => (
  <sup>{props.children}</sup>
);

const CenteredRenderer = (props: PortableTextMarkComponentProps) => (
  <div className="text-center">{props.children}</div>
);

const InternalLinkRenderer = (
  props: PortableTextMarkComponentProps<{ _type: string; slug: string }>
) => <TextLink href={props.value?.slug ?? ''}>{props.children}</TextLink>;

const ExternalLinkRenderer = (
  props: PortableTextMarkComponentProps<{
    _type: string;
    href: string;
    newTab: boolean;
  }>
) =>
  props.value ? (
    <TextLink
      href={props.value.href ?? ''}
      target={props.value.newTab ? '_blank' : ''}
    >
      {props.children}
    </TextLink>
  ) : (
    <></>
  );

const myComponents = {
  block: {
    normal: ({ children }: { children?: ReactNode }) => (
      <Paragraph>{children}</Paragraph>
    ),
    blockquote: ({ children }: { children?: ReactNode }) => (
      <blockquote className="italic m-6">{children}</blockquote>
    ),
    h2: ({ children }: { children?: ReactNode }) => <h2>{children}</h2>,
    h3: ({ children }: { children?: ReactNode }) => <h3>{children}</h3>,
  },
  // types: {},
  marks: {
    sup: SuperscriptRenderer,
    sub: SubscriptRenderer,
    center: CenteredRenderer,
    internalLink: InternalLinkRenderer,
    link: ExternalLinkRenderer,
  },
};

export function PortableTextBlockRenderer({
  content,
}: {
  content: PortableTextBlock | PortableTextBlock[];
}) {
  return <PortableText value={content} components={myComponents} />;
}
