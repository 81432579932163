import Image from 'next/image';
import Link from 'next/link';

import logo from '../../public/images/Tab logo.svg';

import { Menu } from './Menu';

function Logo() {
  return (
    <div className="z-2 relative mt-[-110px] h-[191px] w-[158px] xl:mt-[-130px] xl:h-[255px] xl:w-[210px]">
      <Image src={logo} alt="Monk Lawyers logo" quality={85} fill />
    </div>
  );
}

export function Header() {
  return (
    <>
      <header className="relative flex h-[112px] justify-between bg-blue px-5 py-0 xl:px-[155px]">
        <Link href="/">
          <Logo />
        </Link>
        <Menu />
      </header>
    </>
  );
}
