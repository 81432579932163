import { ReactNode } from 'react';

export function ConfirmButton({
  children,
  ...props
}: { children: ReactNode } & Record<string, any>) {
  return (
    <button
      className="align-center flex items-center justify-center border-none bg-tan px-6 py-2 text-base font-normal text-blue hover:bg-blue-light"
      {...props}
      type="button"
    >
      {children}
    </button>
  );
}

export function ButtonRound({
  children,
  ...props
}: { children: ReactNode } & Record<string, any>) {
  return (
    <button
      {...props}
      className="clickable align-center flex h-[94px] w-[94px] !font-bold items-center justify-center self-end rounded-full border-none bg-tan font-secondary text-xs md:h-[144px] md:w-[144px] md:text-xl"
    >
      {children}
    </button>
  );
}
