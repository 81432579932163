import { GetStaticProps } from 'next';
import Head from 'next/head';
import Image from 'next/image';
import { groq } from 'next-sanity';
import { ReactElement } from 'react';

import { LinkButtonRound } from '@components/Buttons/LinkButton';
import { StandardLayout } from '@components/Layouts';
import { Paragraph, TextLink } from '@components/Typography';
import { PortableTextBlockRenderer } from '@components/Typography/PortableText';
import { shimmer, toBase64, urlFor } from '@lib/images';
import { sanityFetch } from '@lib/sanity.fetch';

import squigglyLine from '../public/images/Squiggly line.png';

export interface PageData {
  metaTitle: string;
  metaDescription: string;
  tagline: string;
  services: string[];
  intro: any[];
  image: {
    alt: string;
    blur: string;
    src: string;
  };
}

const query = groq`*[_type == "home"]
{
  metaTitle,
  metaDescription,
  tagline,
  services,
  image,
  intro
}[0]`;

export default function Home({
  pageData,
}: {
  draftMode: boolean;
  pageData: PageData;
}) {
  return (
    <>
      <Head>
        <title key="title">
          {pageData.metaTitle
            ? pageData.metaTitle
            : 'Monk Lawyers - Leading Tasmanian Criminal Law Firm'}
        </title>
        <meta name="title" content={pageData.metaTitle} key="metatitle" />
        <meta
          name="description"
          content={pageData.metaDescription}
          key="metadescription"
        />
      </Head>

      <main className="mt-5">
        <div className="flex min-h-[370px] place-items-center">
          <span className="m-auto max-w-[670px] text-center font-secondary text-2xl font-bold">
            {pageData.tagline}
          </span>
        </div>

        <section className="flex min-h-[702px] flex-wrap items-center justify-center gap-[60px] bg-cream px-10 pt-8 text-blue md:gap-[135px]">
          <div className="relative h-[240px] w-[240px] tiny:h-[300px] tiny:w-[300px] sm:h-[500px] sm:w-[500px]">
            <Image
              src={pageData.image.src}
              sizes="(max-width: 400px) 240px, (max-width: 600px) 300px, 500px"
              fill
              className="h-[unset] w-fit object-contain"
              alt={pageData.image.alt}
              placeholder="blur"
              blurDataURL={pageData.image.blur}
              quality={85}
            />
          </div>
          <div className="relative mb-12 h-full max-w-[555px] flex flex-col flex-wrap items-center justify-center gap-5 pb-8 text-center font-secondary font-bold md:mb-0 md:pb-10 md:text-left">
            <div style={{ paddingBottom: '50px' }}>
              <PortableTextBlockRenderer content={pageData.intro} />
            </div>
            <LinkButtonRound
              href="/contact"
            >
              contact now
            </LinkButtonRound>
          </div>
        </section>
        
        <section className="background-dark mx-auto my-0 min-h-[624px] max-w-[1000px] px-8 py-[94px] xl:px-0">
          <h2>Services</h2>
          <ul className="m-0 list-none columns-1 p-0 py-8 xl:columns-2">
            {pageData.services.map((service) => (
              <li
                className="font-primary text-base font-light text-tan no-underline before:mr-1 before:font-bold before:content-['/'] py-0.5"
                key={service}
              >
                {service}
              </li>
            ))}
            <li className="font-primary text-base text-tan no-underline before:mr-1 before:font-bold before:content-['/'] mt-5 font-bold">
              <TextLink
                href="/services"
                className='font-secondary text-xl !font-bold'
              >
                more
              </TextLink>
            </li>
          </ul>
        </section>
        
        <div className="m-auto bg-cream px-8 py-12.5 text-center font-secondary text-sm font-normal not-italic text-blue flex flex-col place-items-center">
          <Image src={squigglyLine} alt="squiggly line" width={200} />
          <Paragraph className="max-w-[756px] m-auto">
            Monk Lawyers acknowledge and pay respect to the Tasmanian Aboriginal
            people as the traditional owners and continuing custodians of the
            land, waterways and skies of this island, lutruwita, on which we
            live and work.
          </Paragraph>
          <Image src={squigglyLine} alt="squiggly line" width={200} />
        </div>
      </main>
    </>
  );
}

Home.getLayout = function getLayout(page: ReactElement) {
  return <StandardLayout>{page}</StandardLayout>;
};

export const getStaticProps: GetStaticProps = async ({
  params,
  draftMode = false,
}) => {
  const data = await sanityFetch<PageData>({ draftMode, query });

  const blur = `data:image/svg+xml;base64,${toBase64(shimmer(2000, 1200))}`;

  const src = urlFor(data.image).url();

  const pageData: PageData = {
    ...data,
    image: { src, alt: data.image.alt, blur },
  };

  return {
    props: {
      pageData,
    },
  };
};
